import { Option } from 'src/components/shared/FormFieldGenerator';

export const numberOfEmployeeOptions: Option[] = [
  { value: '1-10', label: '1 - 10', id: 1 },
  { value: '11-50', label: '11 - 50', id: 2 },
  { value: '51-100', label: '51 - 100', id: 3 },
  { value: '101-250', label: '101 - 250', id: 4 },
  { value: '251-1000', label: '251 - 1000', id: 5 },
  { value: '1001-5000', label: '1001 - 5000', id: 6 },
  { value: '5000-10000+', label: '5000 - 10000+', id: 7 },
];
