import { useState } from 'react';
import { Input } from 'src/components/ui/input';
import { UseFormRegister } from 'react-hook-form';
import { EyeIcon, EyeOffIcon } from 'lucide-react';

const PasswordInput = ({
  placeholder = 'Password',
  register,
  name,
  label,
}: {
  placeholder?: string;
  register: UseFormRegister<any>;
  name: string;
  label: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative mt-1">
      <Input
        type={showPassword ? 'text' : 'password'}
        id={`input-${label}`}
        className="input-class pr-10"
        placeholder={placeholder}
        {...register(name)}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 flex items-center pr-3 shadow-none focus:shadow-none"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? (
          <EyeIcon size={20} color="#848C99" />
        ) : (
          <EyeOffIcon size={20} color="#848C99" />
        )}
      </button>
    </div>
  );
};

export default PasswordInput;
