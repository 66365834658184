import { getFirstLetters } from 'src/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { FC } from 'react';
import { cn } from 'src/lib/utils';

interface UserInfoProps {
  user: { name: string; identity: string; avatar: string | null };
  className?: string;
}

export const UserInfo: FC<UserInfoProps> = ({ user, className }) => {
  const alternativeAvatar = getFirstLetters(user.name);

  return (
    <div
      className={cn(
        'flex items-center justify-between border-t border-t-neutral-50 px-6 py-4',
        className,
      )}
    >
      {(user.avatar || user.name) && (
        <Avatar className="mr-2 h-8 w-8 rounded-full">
          {user.avatar ? (
            <AvatarImage src={user.avatar} alt={user.name} />
          ) : (
            <AvatarFallback className="rounded-full">{alternativeAvatar}</AvatarFallback>
          )}
        </Avatar>
      )}
      <div className="flex-1 text-left text-sm">
        <span className="block truncate font-semibold">{user.name}</span>
        <span className="block truncate text-xs text-gray-600">{user.identity}</span>
      </div>
    </div>
  );
};
