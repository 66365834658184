import { FC } from 'react';
import Modal from '.';
import { Button } from 'src/components/ui/button';
import { ReactComponent as DeleteIcon } from 'src/assets/circular-delete.svg';

interface DeleteProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
  handleCancel: () => void;
  title?: string;
  description?: string;
}

const DeleteModal: FC<DeleteProps> = ({
  open,
  setOpen,
  handleDelete,
  handleCancel,
  title = '',
  description = '',
}) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-11 sm:w-[400px]">
        <DeleteIcon className="mb-4" />
        {title && <p className="text-lg font-semibold capitalize"> {title}</p>}
        {description && <p className="text-sm text-neutral-300">{description}</p>}
        <div className="mt-8 flex justify-between">
          <Button type="button" onClick={handleDelete} variant="destructive" className="px-14 py-2">
            {' '}
            Delete
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            variant="outline"
            className="px-14 py-2 hover:bg-slate-50"
          >
            {' '}
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
