import { z } from 'zod';

export const CreateDepartmentSchema = z.object({
  name: z.string().min(1, { message: 'Department name is required' }),
});

export const UpdateDepartmentSchema = z.object({
  name: z.string().min(1, { message: 'Department name is required' }),
  departmentId: z.string().min(1, { message: 'Please select a department to update' }),
});
