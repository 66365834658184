import { useEffect, useState } from 'react';
import DropdownButton, { DropdownOption } from 'src/components/shared/DropdownButton';
import { ReactComponent as AddTeam } from 'src/assets/add-team.svg';
import { Plus, Trash2, ChevronDown } from 'lucide-react';
import Drawer from 'src/components/shared/Drawer';
import { FormGenerator } from 'src/components/shared/FormFieldGenerator';
import { CREATE_DEPARTMENT_FORM } from '../../constant/form';
import {
  useCreateDepartment,
  useGetDepartments,
  useUpdateDepartment,
  useDeleteDepartment,
} from '../../api/hooks/department';
import { Button } from 'src/components/ui/button';
import { Loader } from '../../components/shared/Loader';
import EmployeesTable from '../../components/employee/EmployeesTable';
import { useAppSelector, RootState } from '../../redux/store';
import { useCreateEmployee } from '../../api/hooks/employees';
import { Option } from 'src/components/shared/FormFieldGenerator';
import { accessLevel } from 'src/constant/data';

type FORM_TYPE =
  | 'CREATE_DEPARTMENT'
  | 'UPDATE_DEPARTMENT'
  | 'CREATE_EMPLOYEE'
  | 'UPDATE_EMPLOYEE'
  | 'BULK_EMPLOYEE';

const Employee = () => {
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState<FORM_TYPE>('CREATE_DEPARTMENT');
  const { companyId } = useAppSelector((state: RootState) => state.user);
  const { data: departments } = useGetDepartments(companyId);

  const { handleCreateDepartment, isPending, isSuccess, register, errors } = useCreateDepartment();
  const {
    setValue,
    watch,
    register: UpdateDepartmentRegister,
    handleUpdateDepartment,
    errors: UpdateDepartmentErrors,
    isSuccess: UpdateDepartmentSuccess,
    isPending: isUpdateDepartmentPending,
  } = useUpdateDepartment();
  const {
    handleDeleteDepartment,
    isPending: isDeleteDepartmentPending,
    isSuccess: isDeleteDepartmentSucesss,
  } = useDeleteDepartment();

  const {
    isPending: isPendingCreateEmplyee,
    handleCreateEmployee,
    register: registerCreateEmployee,
    errors: CreateEmployeeErrors,
    watch: watchCreateEmployee,
    setValue: setValueCreateEmployee,
    isSuccess: isSuccessCreateEmployee,
  } = useCreateEmployee();

  const departmentActions: DropdownOption[] = [
    {
      id: 1,
      text: 'Create new department',
      onClick: () => {
        setOpen(true);
        setFormType('CREATE_DEPARTMENT');
      },
    },
    {
      id: 2,
      text: 'Update existing department',
      onClick: () => {
        setOpen(true);
        setFormType('UPDATE_DEPARTMENT');
      },
    },
  ];
  const employeesActions: DropdownOption[] = [
    {
      id: 1,
      text: 'Add Employee Individually',
      icon: <Plus className="h-5 w-5" />,
      onClick: () => {
        setOpen(true);
        setFormType('CREATE_EMPLOYEE');
      },
    },
    {
      id: 2,
      text: 'Add Bulk Employee',
      icon: <AddTeam className="h-5 w-5" />,
      onClick: () => {
        setOpen(true);
        setFormType('BULK_EMPLOYEE');
      },
    },
    {
      id: 2,
      text: 'Import CSV',
      icon: <AddTeam className="h-5 w-5" />,
    },
  ];

  useEffect(() => {
    if (isSuccess || UpdateDepartmentSuccess || isSuccessCreateEmployee) {
      setOpen(false);
    }
  }, [isSuccess, UpdateDepartmentSuccess, isSuccessCreateEmployee]);

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    switch (formType) {
      case 'CREATE_DEPARTMENT':
        handleCreateDepartment();
        break;
      case 'UPDATE_DEPARTMENT':
        handleUpdateDepartment();
        break;
      case 'CREATE_EMPLOYEE':
        handleCreateEmployee();
        break;
      // case 'BULK_EMPLOYEE':
      //   // Add logic here for bulk employee addition if needed
      //   break;
      default:
        console.warn('Unsupported form type');
    }
  };
  const getFormContent = () => {
    switch (formType) {
      case 'CREATE_DEPARTMENT':
        return {
          buttonText: 'Create Department',
          hearderText: 'Create new department',
          headerDesc: 'Add new deparment',
        };
      case 'UPDATE_DEPARTMENT':
        return {
          buttonText: 'Update department',
          hearderText: 'Update existing department',
          headerDesc: 'Deparment information',
        };
      case 'CREATE_EMPLOYEE':
        return {
          buttonText: 'Add Employee',
          hearderText: 'Add Employee',
          headerDesc: 'Add new employee',
        };
      case 'UPDATE_EMPLOYEE':
        return {
          buttonText: 'Save',
          hearderText: 'Employee Details',
          headerDesc: 'Employee information',
        };
      default:
        return {
          buttonText: '',
          hearderText: '',
          headerDesc: '',
        };
    }
  };

  const isLoading =
    isPending || isUpdateDepartmentPending || isPendingCreateEmplyee || isDeleteDepartmentSucesss;

  const paymentRate = [
    {
      id: 1,
      label: 'Hourly',
      value: 'Hourly',
    },
    {
      id: 2,
      label: 'Annually',
      value: 'Annually',
    },
  ];

  const { buttonText, headerDesc, hearderText } = getFormContent();

  return (
    <div>
      <div className="mb-8 flex flex-col justify-between sm:flex-row">
        <h2 className="text-2xl font-semibold">Employee Directory</h2>
        <div className="mt-4 flex sm:mt-0">
          <DropdownButton
            triggerDetails={{
              title: 'Department',
              icon: <ChevronDown />,
              iconPosition: 'right',
              variant: 'outline',
              className:
                'border-primary-500 hover:bg-primary-500/5 text-primary-500 hover:text-primary-500 mr-2 cursor-pointer',
            }}
            options={departmentActions}
          />
          <DropdownButton
            triggerDetails={{
              title: 'Add Employee',
              icon: <AddTeam className="h-6 w-6 text-white" />,
              iconPosition: 'left',
              className: 'bg-primary-500  hover:bg-primary-500/90 text-white',
            }}
            options={employeesActions}
          />
        </div>
      </div>
      <EmployeesTable />
      <Drawer
        open={open}
        setOpen={setOpen}
        header={{
          title: hearderText,
          description: headerDesc,
        }}
      >
        <div className="h-full">
          <form onSubmit={onSubmit} className="flex h-full flex-col">
            <div className="flex-1 space-y-5">
              {formType === 'CREATE_DEPARTMENT' &&
                CREATE_DEPARTMENT_FORM.map((field) => (
                  <FormGenerator {...field} key={field.id} register={register} errors={errors} />
                ))}
              {formType === 'UPDATE_DEPARTMENT' && (
                <>
                  <FormGenerator
                    key={1}
                    watch={watch}
                    setValue={setValue}
                    register={UpdateDepartmentRegister}
                    errors={UpdateDepartmentErrors}
                    inputType="select"
                    placeholder="Select a department"
                    label="Department"
                    name="departmentId"
                    options={departments as unknown as Option[]}
                  />

                  <FormGenerator
                    key={2}
                    register={UpdateDepartmentRegister}
                    errors={UpdateDepartmentErrors}
                    inputType="input"
                    placeholder="Department name"
                    name="name"
                    label="Department name"
                  />
                </>
              )}
              {formType === 'CREATE_EMPLOYEE' && (
                <>
                  <div className="flex justify-between">
                    <FormGenerator
                      register={registerCreateEmployee}
                      key={1}
                      errors={CreateEmployeeErrors}
                      inputType="input"
                      placeholder="First name"
                      label="First name"
                      name="firstName"
                      className="w-[49%]"
                    />
                    <FormGenerator
                      key={2}
                      register={registerCreateEmployee}
                      errors={CreateEmployeeErrors}
                      inputType="input"
                      placeholder="Last name"
                      label="Last name"
                      name="lastName"
                      className="w-[49%]"
                    />
                  </div>
                  <div className="flex justify-between">
                    <FormGenerator
                      key={3}
                      register={registerCreateEmployee}
                      errors={CreateEmployeeErrors}
                      inputType="input"
                      type="email"
                      placeholder="Email"
                      label="email"
                      name="email"
                      className="w-[49%]"
                    />
                  </div>
                  <div className="flex justify-between">
                    <FormGenerator
                      key={5}
                      register={registerCreateEmployee}
                      errors={CreateEmployeeErrors}
                      inputType="input"
                      placeholder="Role"
                      label="Role"
                      name="role"
                      className="w-[49%]"
                    />
                    <FormGenerator
                      key={6}
                      register={registerCreateEmployee}
                      errors={CreateEmployeeErrors}
                      inputType="date"
                      placeholder="Start Date"
                      label="Start Date"
                      name="startDate"
                      className="w-[49%]"
                      setValue={setValueCreateEmployee}
                      watch={watchCreateEmployee}
                    />
                  </div>
                  <FormGenerator
                    key={7}
                    watch={watchCreateEmployee}
                    setValue={setValueCreateEmployee}
                    register={registerCreateEmployee}
                    errors={CreateEmployeeErrors}
                    inputType="select"
                    placeholder="Select a department"
                    label="Department"
                    name="department"
                    options={departments as unknown as Option[]}
                  />
                  <FormGenerator
                    key={8}
                    watch={watchCreateEmployee}
                    setValue={setValueCreateEmployee}
                    register={registerCreateEmployee}
                    errors={CreateEmployeeErrors}
                    inputType="select"
                    placeholder="Select a access level"
                    label="Access level"
                    name="accessLevel"
                    options={accessLevel}
                  />
                  <FormGenerator
                    key={9}
                    watch={watchCreateEmployee}
                    setValue={setValueCreateEmployee}
                    register={registerCreateEmployee}
                    errors={CreateEmployeeErrors}
                    inputType="select"
                    placeholder="Select a pay rate"
                    label="Pay rate"
                    name="payRate"
                    options={paymentRate}
                  />
                  <FormGenerator
                    key={10}
                    register={registerCreateEmployee}
                    errors={CreateEmployeeErrors}
                    inputType="input"
                    type="number"
                    placeholder=""
                    label="Wage per hour"
                    name="wagePerHour"
                  />
                </>
              )}
            </div>
            <div className="flex items-center justify-between border-t border-t-neutral-50 pt-6">
              {formType === 'UPDATE_DEPARTMENT' && (
                <Button
                  onClick={() => {
                    handleDeleteDepartment(watch());
                  }}
                  className="bg-transparent font-semibold text-error-500 hover:bg-transparent"
                >
                  <Trash2 className="mr-2" />
                  <Loader loading={isDeleteDepartmentPending}>Delete department</Loader>
                </Button>
              )}
              <div className="flex flex-1 justify-end">
                <Button
                  variant="outline"
                  className="mr-2 font-semibold"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button type="submit" className="bg-primary-500 font-semibold text-white">
                  <Loader loading={isLoading}>{buttonText}</Loader>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
};

export default Employee;
