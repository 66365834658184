import { cn } from '../../lib/utils';
import { Loader2 } from 'lucide-react';
import React from 'react';

type LoaderProps = {
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
  header?: React.ReactNode | string;
};
export const Loader = ({ loading = false, header, children, className }: LoaderProps) => {
  return (
    <div className={cn('flex w-full flex-col items-center justify-center', className)}>
      {header}
      {loading ? <Loader2 /> : <>{children} </>}
    </div>
  );
};
