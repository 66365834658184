import { setAccessToken, getRefreshToken, setRefreshToken, clearTokens } from '../../lib/token';
import { apiClient } from '../clients/apiClient';

export const refreshAccessToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    const response = await apiClient.post('/auth/refresh-token', {
      refreshToken,
    });

    const { accessToken, refreshToken: newRefreshToken } = response.data;
    setAccessToken(accessToken);
    setRefreshToken(newRefreshToken);

    return accessToken;
  } catch (error) {
    clearTokens(); // Clear tokens if refreshing fails
    throw new Error('Session expired');
  }
};
