import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { APIError } from './authentication';
import { toast } from 'src/lib/toast';
import { AddEmplyoeeSchema, UpdateEmployeeSchema } from '../../validations/employee';
import {
  createEmployee,
  deletEemployee,
  getAllOfACompany,
  updateEmployee,
} from '../../api/services/employeeServices';
import { Employee, FetchEmployeesParams, UpdateEmployee } from 'src/types/employee';

export const EMPLOYEES = 'employees';

export const useCreateEmployee = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<z.infer<typeof AddEmplyoeeSchema>>({
    resolver: zodResolver(AddEmplyoeeSchema),
    mode: 'onTouched',
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: createEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYEES] });
      reset();
      toast({
        message: 'Employee created successfully',
        position: 'top-center',
        type: 'success',
      });
    },
    onError: (err: APIError) => {
      toast({
        message: err.response.data.message || 'Oops! something went wrong',
        position: 'top-center',
        type: 'error',
      });
    },
  });

  const handleCreateEmployee = handleSubmit(async (data: Employee) => {
    return mutate(data);
  });

  return {
    handleCreateEmployee,
    isPending,
    isSuccess,
    register,
    errors,
    watch,
    setValue,
  };
};

export const useUpdateEmployee = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<UpdateEmployee>({
    resolver: zodResolver(UpdateEmployeeSchema),
    mode: 'onTouched',
    defaultValues: {},
  });

  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: updateEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYEES] });
      reset();
      toast({
        message: 'Employee created successfully',
        position: 'top-center',
        type: 'success',
      });
    },
    onError: (err: APIError) => {
      toast({
        message: err.response.data.message || 'Oops! something went wrong',
        position: 'top-center',
        type: 'error',
      });
    },
  });

  const handleUpdateEmployee = handleSubmit(async (data: UpdateEmployee) => {
    return mutate(data);
  });

  return {
    handleUpdateEmployee,
    reset,
    isPending,
    isSuccess,
    register,
    errors,
    watch,
    setValue,
  };
};

export const useGetAllOfACompany = (params: FetchEmployeesParams) => {
  return useQuery({
    queryKey: [EMPLOYEES, params],
    queryFn: () => getAllOfACompany(params),
  });
};

export const useDeleteEmployee = () => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: deletEemployee,
    onSuccess: () => {
      toast({
        message: `Department delete successfully`,
        position: 'top-left',
        type: 'success',
        title: '',
      });
    },
  });
  const queryClient = useQueryClient();

  const handleDeleteEmployee = (id: string) => {
    if (!id) {
      toast({
        message: 'Please select a employee to delete',
        position: 'top-center',
        type: 'error',
        title: 'Error',
      });
      return;
    }
    mutate(id || '');
  };

  if (isSuccess) {
    queryClient.invalidateQueries({ queryKey: [EMPLOYEES] });
  }
  return {
    handleDeleteEmployee,
    isPending,
    isSuccess,
  };
};
