import { toast as sonner } from 'sonner';

type Position =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-center'
  | 'bottom-center';
type ToastTypes = 'success' | 'error' | 'info';

interface Toast {
  type: ToastTypes;
  title?: string;
  message: string;
  duration?: number;
  position?: Position;
}

export const toast = ({
  type,
  message = '',
  position = 'top-right',
  duration = 4000,
  title = '',
}: Toast) => {
  return sonner(title, {
    description: message,
    position,
    duration,
    classNames: {
      title: 'text-white text-2xl',
      description: 'text-white font-semibold',
      toast: type === 'error' ? 'bg-error-500' : 'bg-green-400 border border-none',
    },
  });
};
