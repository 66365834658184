'use client';
import { ChevronDown, type LucideIcon } from 'lucide-react';
import React, { FC, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from 'src/components/ui/sidebar';
interface MenuItem {
  title: string;
  url: string;
  onClick?: () => void;
  icon?: FC<any> | LucideIcon;
  items?: MenuItem[];
}

interface NavMainProps extends React.ComponentPropsWithoutRef<typeof SidebarGroup> {
  items: MenuItem[];
}

export const NavMain: React.FC<NavMainProps> = ({ items, ...props }) => {
  const location = useLocation();

  return (
    <SidebarGroup {...props}>
      <SidebarMenu>
        {items.map((item: MenuItem) => {
          const isActive = location.pathname === `/${item.url}`;

          return (
            <Fragment key={item.title}>
              {item.items?.length ? (
                <Collapsible
                  key={item.title}
                  asChild
                  defaultOpen={isActive}
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton
                        tooltip={item.title}
                        className="h-12 px-4 py-3 hover:bg-primary-50 active:bg-primary-50"
                      >
                        {item.icon && (
                          <item.icon className="h-6 w-6" color={isActive ? '#704EF1' : 'black'} />
                        )}
                        <span>{item.title}</span>
                        {item.items?.length && (
                          <ChevronDown className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-180" />
                        )}
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub className="px-0">
                        {item.items?.map((subItem) => {
                          const isActiveSubItem =
                            location.pathname === `/${subItem.url}` ||
                            location.pathname.includes(subItem.url);
                          return (
                            <SidebarMenuSubItem key={subItem.url}>
                              <SidebarMenuSubButton
                                isActive={isActiveSubItem}
                                asChild
                                className="h-12 px-4 hover:bg-primary-50 active:bg-primary-50"
                              >
                                <Link to={subItem.url}>
                                  <span>{subItem.title}</span>
                                </Link>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          );
                        })}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              ) : (
                <SidebarMenuButton
                  asChild
                  isActive={isActive}
                  className="h-12 px-4 py-3"
                  {...(item.onClick ? { onClick: item.onClick } : {})}
                >
                  <Link to={item.url}>
                    {item.icon && <item.icon color={isActive ? '#704EF1' : 'black'} />}
                    <span>{item.title}</span>
                  </Link>
                </SidebarMenuButton>
              )}
            </Fragment>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
};
