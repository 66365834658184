export const status = [
  { value: 'ACTIVE', label: 'Active', id: '1' },
  { value: 'INACTIVE', label: 'Inactive', id: '2' },
];

export const accessLevel = [
  { value: 'Employee', label: 'Employee', id: 1 },
  { value: 'Manager', label: 'Manager', id: 2 },
  { value: 'Admin', label: 'Administration', id: 3 },
];
