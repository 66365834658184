const SkeletonTable: React.FC<{ rows: number }> = ({ rows }) => {
  return (
    <table className="min-w-full table-fixed border-collapse border-x border-t border-neutral-50">
      <tbody>
        {[...Array(rows)].map((_, index) => (
          <tr key={index} className="border-b border-neutral-200">
            <td className="flex items-center px-4 py-2">
              <div className="mx-2 h-4 w-4 animate-pulse bg-gray-300"></div>
              <div className="mr-2 h-8 w-8 animate-pulse rounded-full bg-gray-300"></div>
              <div className="h-4 w-16 animate-pulse rounded bg-gray-300"></div>
            </td>
            <td className="px-4 py-2">
              <div className="h-4 w-16 animate-pulse rounded bg-gray-300"></div>
            </td>
            <td className="px-4 py-2">
              <div className="h-4 w-28 animate-pulse rounded bg-gray-300"></div>
            </td>
            <td className="px-4 py-2">
              <div className="h-4 w-24 animate-pulse rounded bg-gray-300"></div>
            </td>
            <td className="px-4 py-2">
              <div className="h-4 w-20 animate-pulse rounded bg-gray-300"></div>
            </td>
            <td className="px-4 py-2">
              <div className="h-4 w-16 animate-pulse rounded bg-gray-300"></div>
            </td>
            <td className="px-4 py-2">
              <div className="h-4 w-1 animate-pulse rounded bg-gray-300"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SkeletonTable;
