import { numberOfEmployeeOptions } from './numberOfEmployment';
import { Option } from 'src/components/shared/FormFieldGenerator';

export type FormProps = {
  id: string;
  type?: 'email' | 'text' | 'password';
  inputType: 'select' | 'input' | 'textarea' | 'date';
  options?: Option[];
  label?: string;
  placeholder: string;
  name: string;
};

export const SIGN_UP_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'Enter company name',
    label: 'Company Name',
    name: 'companyName',
    type: 'text',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
  {
    id: '3',
    inputType: 'select',
    placeholder: 'Select an Option',
    label: 'Number of Employees',
    name: 'numberOfEmployees',
    type: 'text',
    options: numberOfEmployeeOptions,
  },
  {
    id: '4',
    inputType: 'input',
    placeholder: '',
    label: 'Password',
    name: 'password',
    type: 'password',
  },
];

export const LOGIN_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: '',
    label: 'Password',
    name: 'password',
    type: 'password',
  },
];

export const RESET_PASSWORD_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: '',
    label: 'New Password',
    name: 'newPassword',
    type: 'password',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: '',
    label: 'Comfirm Password',
    name: 'confirmPassword',
    type: 'password',
  },
];

export const FORGOT_PASSWORD_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
];

export const CREATE_DEPARTMENT_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: '',
    label: 'Name',
    name: 'name',
    type: 'text',
  },
];

export const UPDATE_DEPARTMENT_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: '',
    label: 'name',
    name: 'name',
    type: 'text',
  },
];

export const EMPLOYEE_PERSONAL_INFO_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: '',
    label: 'First Name',
    name: 'firstName',
    type: 'text',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: '',
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
  },
  {
    id: '3',
    inputType: 'date',
    placeholder: '',
    label: 'Date of birth',
    name: 'numberOfEmployees',
  },
  {
    id: '4',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
  {
    id: '5',
    inputType: 'input',
    placeholder: '',
    label: 'Phone number',
    name: 'phoneNumber',
    type: 'text',
  },
  {
    id: '6',
    inputType: 'input',
    placeholder: '',
    label: 'Address',
    name: 'address',
    type: 'text',
  },
];

export const EMPLOYEE_COMPANY_INFO_FORM = (departments = []): FormProps[] => [
  {
    id: '7',
    inputType: 'select',
    placeholder: 'Access Level',
    label: 'Access Level',
    name: 'accessLevel',
    options: [
      {
        label: 'Employee',
        value: 'Employee',
        id: '',
      },
      {
        label: 'Manager',
        value: 'Manager',
        id: '',
      },
      {
        label: 'Administrator',
        value: 'Admin',
        id: '',
      },
    ],
  },
  // {
  //   id: '8',
  //   inputType: 'select',
  //   placeholder: 'Job Type',
  //   label: 'Job Type',
  //   name: 'jobType',
  //   options: [
  //     {
  //       label: 'Fulltime', value: 'Fulltime',
  //       id: ''
  //     },
  //     {
  //       label: 'Partime', value: 'Parttime',
  //       id: ''
  //     },
  //     {
  //       label: 'Contractor', value: 'Contractor',
  //       id: ''
  //     },
  //   ],
  // },
  {
    id: '9',
    inputType: 'input',
    placeholder: 'Software engineer',
    label: 'Role',
    name: 'role',
    type: 'text',
  },
  // {
  //   id: '10',
  //   inputType: 'input',
  //   placeholder: 'Employee ID',
  //   label: 'Employee ID',
  //   name: 'employeeId',
  //   type: 'text',
  // },
  {
    id: '11',
    inputType: 'select',
    placeholder: 'Pay Rate',
    label: 'Pay Rate',
    name: 'payRate',
    options: [
      {
        label: 'Hourly',
        value: 'Hourly',
        id: '',
      },
      {
        label: 'Annually',
        value: 'Annually',
        id: '',
      },
    ],
  },
  // {
  //   id: '12',
  //   inputType: 'select',
  //   placeholder: 'Currency',
  //   label: 'Currency',
  //   name: 'currency',
  //   options: [
  //     {
  //       label: 'GBP', value: 'GBP',
  //       id: ''
  //     },
  //     {
  //       label: 'USD', value: 'USD',
  //       id: ''
  //     },
  //     {
  //       label: 'EUR', value: 'EUR',
  //       id: ''
  //     },
  //   ],
  // },
  {
    id: '13',
    inputType: 'input',
    placeholder: 'Wage',
    label: 'Wage',
    name: 'wage',
    type: 'text',
  },
  {
    id: '14',
    inputType: 'date',
    placeholder: 'Start Date',
    label: 'Start Date',
    name: 'startDate',
  },
  {
    id: '15',
    inputType: 'date',
    placeholder: 'End Date',
    label: 'End Date',
    name: 'endDate',
  },
  {
    id: '16',
    inputType: 'select',
    placeholder: '',
    label: 'Department',
    name: 'department',
    options: departments,
  },
];
