import { protectedApiClient } from '../clients/apiClient';
import { Employee, FetchEmployeesParams, UpdateEmployee } from '@/types/employee';

export const createEmployee = async (employeeDetails: Employee): Promise<any> => {
  const { data } = await protectedApiClient.post('/employees', employeeDetails);
  return data;
};

export const updateEmployee = async (employeeDetails: UpdateEmployee): Promise<UpdateEmployee> => {
  const { _id, ...restDetails } = employeeDetails;
  const { data } = await protectedApiClient.put(`/employees/${_id}`, restDetails);
  return data;
};

export const getAllOfACompany = async (params: FetchEmployeesParams): Promise<any> => {
  const { data } = await protectedApiClient.get('/employees', {
    params,
  });
  return data;
};

export const deletEemployee = async (employeeId: string): Promise<any> => {
  const { data } = await protectedApiClient.delete(`/employees/${employeeId}`);
  return data;
};
