import { z } from 'zod';

const numberOfEmployeesSchema = z.enum([
  '',
  '1-10',
  '11-50',
  '51-100',
  '101-250',
  '251-1000',
  '1001-5000',
  '5000-10000+',
]);

const passwordSchema = z
  .string()
  .min(8, { message: 'Password must be at least 8 characters long' })
  .max(32, { message: 'Password must be at most 32 characters long' })
  .regex(/[A-Z]/, {
    message: 'Password must contain at least one uppercase letter',
  })
  .regex(/[a-z]/, {
    message: 'Password must contain at least one lowercase letter',
  })
  .regex(/[0-9]/, { message: 'Password must contain at least one number' });
// .regex(/[\W_]/, { message: 'Password must contain at least one special character' });

export const SignupSchema = z.object({
  companyName: z.string().min(2, { message: 'Company name must be atleast 2 characters long' }),
  numberOfEmployees: numberOfEmployeesSchema,
  email: z.string().email('You must give a valid email'),
  password: passwordSchema,
});

export const LoginSchema = z.object({
  email: z.string().email('You must give a valid email'),
  password: z.string().min(8, { message: 'Your password must be atleast 8 characters long' }),
});

export const ResetPasswordSchema = z
  .object({
    newPassword: passwordSchema,
    confirmPassword: z.string().min(1, { message: 'Confirm Password is required' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'], // path to add error message
  });

export const ForgotPasswordSchema = z.object({
  email: z.string().email('You must give a valid email'),
});
