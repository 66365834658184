import { FC } from 'react';
import { Dialog, DialogContent } from 'src/components/ui/dialog';

interface ModalProps {
  open?: boolean;
  children: React.ReactNode;
  setOpen: (open: boolean) => void;
}

const Modal: FC<ModalProps> = ({ open, setOpen, children }) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex w-full justify-center">{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
