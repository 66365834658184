import React from 'react';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '../ui/sheet';
import { cn } from 'src/lib/utils';

const Drawer = ({
  open,
  setOpen,
  header,
  children,
  className,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  header: { title: string; description: string };
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent
          className={cn('flex w-[90%] flex-col px-0 sm:w-[580px] sm:max-w-full', className)}
        >
          {header && (
            <SheetHeader className="border-b px-6 pb-6">
              <SheetTitle>{header.title}</SheetTitle>
              <SheetDescription>{header.description}</SheetDescription>
            </SheetHeader>
          )}
          <div className="px-6">{children}</div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default Drawer;
