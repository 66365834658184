import React, { useState, ReactNode } from 'react';
import { cn } from 'src/lib/utils';

interface Tab {
  key: string;
  label: string;
  content: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, className }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  return (
    <div>
      <div className={cn('flex space-x-4', className)}>
        {tabs.map((tab) => (
          <button
            type="button"
            key={tab.key}
            className={`font-medium" cursor-pointer p-2 ${
              activeTab === tab.key ? 'text-primary-500' : 'text-neutra-200'
            }`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-4">
        {tabs.map(
          (tab) =>
            activeTab === tab.key && (
              <div key={tab.key} className="tab-content">
                {tab.content}
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default Tabs;
