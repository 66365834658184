import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import ErrorPage from './error-page';
import DashboardLayout from './pages/dashboard/DashboardLayout';
import Signup from './pages/onboarding/Signup';
import Login from './pages/onboarding/Login';
import ForgetPassword from './pages/onboarding/ForgetPassword';
import ResetPassword from './pages/onboarding/ResetPassword';
import Dashboard from './pages/dashboard/Dashboard';
import Notify from './pages/onboarding/Notify';
import ProtectedRoute from './components/ProtectedRoute';
import ActivationEmailSent from './pages/onboarding/ActivationEmailSent';
import ResetPasswordEmailSent from './pages/onboarding/ResetPasswordEmailSent';
import VerifyEmail from './pages/onboarding/VerifyEmail';
import Employees from './pages/employee/Employees';

const routes = createBrowserRouter([
  {
    path: 'signup',
    element: <Signup />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forgot-password',
    element: <ForgetPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmail />,
  },
  {
    path: '/notify',
    element: <Notify />,
    children: [
      {
        path: 'activation',
        element: <ActivationEmailSent />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordEmailSent />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'employees/directory',
        element: <Employees />,
      },
    ],
  },
]);

const App = () => <RouterProvider router={routes} />;

export default App;
