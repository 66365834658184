import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createDepartment,
  deleteDepartment,
  getAllDepartmentsForCompany,
  updateDepartment,
} from '../services/departmentServices';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { CreateDepartmentSchema, UpdateDepartmentSchema } from '../../validations/department';
import { DepartmentType } from '../../types/department';
import { toast } from 'src/lib/toast';

const DEPARTMENT = 'departments';
export const useCreateDepartment = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<z.infer<typeof CreateDepartmentSchema>>({
    resolver: zodResolver(CreateDepartmentSchema),
    mode: 'onTouched',
  });
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: createDepartment,
    onSuccess: () => {
      toast({
        message: 'Department created successfully',
        position: 'top-center',
        type: 'success',
        title: '',
      });
      queryClient.invalidateQueries({ queryKey: [DEPARTMENT] });
      reset();
    },
  });

  const handleCreateDepartment = handleSubmit(async (data: DepartmentType) => {
    mutate(data);
  });

  return {
    handleCreateDepartment,
    isPending,
    isSuccess,
    register,
    errors,
  };
};

export const useUpdateDepartment = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<z.infer<typeof UpdateDepartmentSchema>>({
    resolver: zodResolver(UpdateDepartmentSchema),
    mode: 'onTouched',
  });
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: updateDepartment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEPARTMENT] });
      reset();
      toast({
        message: 'Department updated successfully',
        position: 'top-left',
        type: 'success',
      });
    },
  });

  const handleUpdateDepartment = handleSubmit(async (data: DepartmentType) => {
    mutate(data);
  });

  return {
    handleUpdateDepartment,
    isPending,
    isSuccess,
    register,
    errors,
    watch,
    setValue,
  };
};

export const useDeleteDepartment = () => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: deleteDepartment,
    onSuccess: () => {
      toast({
        message: `Department delete successfully`,
        position: 'top-left',
        type: 'success',
        title: '',
      });
    },
  });
  const queryClient = useQueryClient();

  const handleDeleteDepartment = (data: DepartmentType) => {
    if (!data.departmentId) {
      toast({
        message: 'Please select a department to delete',
        position: 'top-center',
        type: 'error',
        title: 'Error',
      });
      return;
    }
    mutate(data.departmentId || '');
  };

  if (isSuccess) {
    queryClient.invalidateQueries({ queryKey: [DEPARTMENT] });
  }
  return {
    handleDeleteDepartment,
    isPending,
    isSuccess,
  };
};

export const useGetDepartments = (companyId: string) => {
  return useQuery({
    queryKey: [DEPARTMENT, companyId],
    queryFn: () => getAllDepartmentsForCompany(companyId),
    enabled: Boolean(companyId),
    select: (data) =>
      data?.map((d: { _id: string; name: string }) => ({
        id: d._id,
        label: d.name,
        value: d._id,
      })),
  });
};
