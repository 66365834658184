import { Card, CardHeader, CardTitle, CardContent } from 'src/components/ui/card';
import BarChat from '../../components/dashboard/BarChart';
import Cards from 'src/components/dashboard/Cards';
import EmployeesTable from 'src/components/employee/EmployeesTable';
import Header from 'src/components/shared/Header';

const Dashboard = () => {
  return (
    <div>
      <Header title="Dashboard" />
      <Cards />
      <Card className="col-span-4">
        <CardHeader>
          <CardTitle className="text-xl">Employee Attendance</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <BarChat />
        </CardContent>
      </Card>
      <div className="mt-8">
        <Header title="Employee Directory" className="text-xl" />
        <EmployeesTable />
      </div>
    </div>
  );
};

export default Dashboard;
