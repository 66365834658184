import { Outlet } from 'react-router-dom';
import Search from 'src/components/shared/Search';
import { ReactComponent as NotificationIcon } from 'src/assets/notication.svg';
import { AppSidebar } from 'src/components/sidebar';
import { SidebarInset, SidebarProvider, SidebarTrigger } from 'src/components/ui/sidebar';
import { useGetUserDetails } from 'src/api/hooks/authentication';

const Dashboard = () => {
  useGetUserDetails();

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset className="max-w-full overflow-x-hidden">
        <div>
          <section className="flex-1">
            <header className="flex w-full items-center border-b border-b-neutral-50 px-6 py-[18px]">
              <div className="flex items-center gap-2 px-4">
                <SidebarTrigger className="-ml-1" />
              </div>
              <div className="text-md mr-4 flex flex-col font-medium sm:flex-row md:mr-36 md:text-xl">
                <p className="font-medium"> Welcome,</p>
                <p className="font-semibold md:pl-1"> Hammed</p>
              </div>
              <div className="mr-4 flex-1">
                <Search />
              </div>
              <NotificationIcon />
            </header>
            <div className="p-6">
              <Outlet />
            </div>
          </section>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export default Dashboard;
