import React, { FC } from 'react';
import { Button } from 'src/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';

export interface DropdownOption {
  text: string | React.ReactNode;
  id: number | string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

interface DropdownButtonProps {
  triggerDetails: {
    title: string;
    icon?: React.ReactNode;
    iconPosition?: 'right' | 'left';
    className?: string;
    variant?: 'outline' | 'default' | 'link' | 'destructive' | 'secondary' | 'ghost';
  };
  options: DropdownOption[];
  handleClick?: (option: DropdownOption) => void;
  contentClassName?: string;
  className?: string;
}

const DropdownButton: FC<DropdownButtonProps> = ({
  triggerDetails = {
    icon: null,
    iconPosition: 'left',
    variant: 'default',
    title: '',
    className: '',
  },
  className = '',
  options,
}) => {
  return (
    <div>
      <Popover>
        <PopoverTrigger asChild className={className}>
          <Button variant={triggerDetails.variant} className={triggerDetails.className}>
            {triggerDetails.iconPosition === 'left' && (
              <span className="mr-2">{triggerDetails.icon}</span>
            )}
            {triggerDetails.title}
            {triggerDetails.iconPosition === 'right' && (
              <span className="ml-2">{triggerDetails.icon}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[262px]" align="end">
          {options.map((option) => {
            return (
              <div
                key={`${option.text}-${option.id}`}
                className="flex cursor-pointer items-center px-2 py-4 hover:bg-secondary/80"
                onClick={option.onClick}
              >
                {option.icon && <span className="mr-2">{option.icon}</span>}
                {option.text}
              </div>
            );
          })}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default DropdownButton;
