import { apiClient, protectedApiClient } from '../clients/apiClient';
import { LoginType, ResetPasswordType, SignupType, UserType } from '../../types/authentication';

interface AuthResponse {
  user: UserType;
  accessToken: string;
  refreshToken: string;
}

export const signup = async (user: SignupType): Promise<AuthResponse> => {
  const { data } = await apiClient.post('/user/register', user);
  return data;
};

export const login = async (user: LoginType): Promise<AuthResponse> => {
  const { data } = await apiClient.post('/auth/login', user);
  return data;
};

export const forgotPassword = async (email: { email: string }): Promise<{ message: string }> => {
  const { data } = await apiClient.post('/auth/request-password-reset', email);
  return data;
};

export const resetPassword = async (details: ResetPasswordType): Promise<any> => {
  const { data } = await apiClient.post('/auth/reset-password', details);
  return data;
};

export const fetchEmailVerification = async (token: string): Promise<any> => {
  const { data } = await apiClient.get('/auth/verify-email', {
    params: { token },
  });
  return data;
};

export const fetchUserDetails = async (): Promise<UserType> => {
  const { data } = await protectedApiClient.get('/user/profile');
  return data;
};
