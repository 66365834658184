import React, { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button';
import { Settings2 } from 'lucide-react';
import SelectField from 'src/components/shared/SelectField';
import { RootState, useAppSelector } from 'src/redux/store';
import { useGetDepartments } from 'src/api/hooks/department';
import { Option } from 'src/components/shared/FormFieldGenerator';
import { accessLevel, status } from 'src/constant/data';

interface EmployeeFiltersState {
  department?: string;
  status?: string;
  access?: string;
}
interface EmployeeFilterProps {
  filtersState: EmployeeFiltersState;
  setFiltersState: React.Dispatch<React.SetStateAction<EmployeeFiltersState>>;
}

const EmployeeFilter = ({ filtersState, setFiltersState }: EmployeeFilterProps) => {
  const { companyId } = useAppSelector((state: RootState) => state.user);
  const { data: departments, isLoading } = useGetDepartments(companyId);
  const [localFilterState, setLocalFilterState] = useState(filtersState);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div>
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant="outline" className="px-4 py-2">
            <span className="mr-2">
              <Settings2 size={20} />
            </span>
            Filter
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full px-3 py-4 sm:w-[212px]" align="end">
          <SelectField
            label="Department"
            placeholder="Select Department"
            options={
              departments?.map((department: Option) => ({
                ...department,
                value: department.label,
              })) as unknown as Option[]
            }
            loading={isLoading}
            onChange={(value) => setLocalFilterState({ ...localFilterState, department: value })}
            value={localFilterState.department}
          />
          <SelectField
            label="Status"
            placeholder="Select Status"
            options={status}
            onChange={(value) => setLocalFilterState({ ...localFilterState, status: value })}
            value={localFilterState.status}
          />
          <SelectField
            label="Access"
            placeholder="Select Access"
            options={accessLevel}
            onChange={(value) => setLocalFilterState({ ...localFilterState, access: value })}
            value={localFilterState.access}
          />
          <div className="mt-3 flex justify-between">
            <Button
              variant="outline"
              className="hover:bg-white"
              onClick={() => {
                setIsPopoverOpen(false);
                setLocalFilterState({});
                setFiltersState({});
              }}
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                setIsPopoverOpen(false);
                setFiltersState(localFilterState);
              }}
              className="bg-primary-500 px-5 py-2 text-white hover:bg-primary-500/90"
            >
              Apply
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default EmployeeFilter;
