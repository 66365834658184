import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Label } from 'src/components/ui/label';
import { Option } from './FormFieldGenerator';
interface SelectFieldProps {
  label: string;
  placeholder: string;
  options: Option[];
  className?: string;
  value?: string;
  onChange?: (value: string | undefined) => void;
  loading?: boolean;
  onTrigger?: () => void;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  placeholder,
  options,
  className,
  onChange,
  value = '',
  loading = false,
  onTrigger = () => null,
}) => {
  return (
    <div className={`mt-2 ${className}`}>
      <Label>{label}</Label>
      <Select onValueChange={onChange} onOpenChange={(open) => open && onTrigger()}>
        <SelectTrigger
          className="mt-2 w-full text-neutral-300"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <SelectValue placeholder={value || placeholder} />
        </SelectTrigger>
        <SelectContent className="w-full">
          {loading ? (
            <span className="text-[12px] text-neutral-300"> fetching data...</span>
          ) : (
            <SelectGroup>
              {options.map((option) => (
                <SelectItem
                  key={option.id}
                  value={option.value}
                  className={`text-left ${option.value === value ? 'bg-primary-50' : ''}`}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          )}
        </SelectContent>
      </Select>
    </div>
  );
};

export default SelectField;
