import { DepartmentType } from '@/types/department';
import { protectedApiClient } from '../clients/apiClient';

export const createDepartment = async (department: DepartmentType): Promise<any> => {
  const { data } = await protectedApiClient.post('/departments', department);
  return data;
};

export const updateDepartment = async (department: DepartmentType): Promise<any> => {
  const { data } = await protectedApiClient.put(`/departments/${department.departmentId}`, {
    name: department.name,
  });
  return data;
};
export const deleteDepartment = async (departmentId: string): Promise<any> => {
  const { data } = await protectedApiClient.delete(`/departments/${departmentId}`);
  return data;
};

export const getAllDepartmentsForCompany = async (companyId: string): Promise<any> => {
  const { data } = await protectedApiClient.get(`/departments/company/${companyId}`);
  return data;
};
