import moment from 'moment';
import TanstackTable from 'src/components/shared/TanstackTable';
import IndeterminateCheckbox from 'src/components/shared/IndeterminateCheckbox';
import { EllipsisVertical, Trash } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import Drawer from 'src/components/shared/Drawer';
import React, { useState } from 'react';
import EmployeeFilter from 'src/components/employee/EmployeeFilter';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import { EyeIcon } from 'lucide-react';
import Tabs from '../shared/Tabs';
import { UserInfo } from '../shared/UserInfo';
import { Button } from '../ui/button';
import { EMPLOYEE_PERSONAL_INFO_FORM, EMPLOYEE_COMPANY_INFO_FORM } from 'src/constant/form';
import { FormGenerator } from '../shared/FormFieldGenerator';
import { useDeleteEmployee, useUpdateEmployee } from 'src/api/hooks/employees';
import { RootState, useAppSelector } from 'src/redux/store';
import { useGetDepartments } from 'src/api/hooks/department';
import { FetchEmployeesParams, UpdateEmployee } from 'src/types/employee';
import { getAllOfACompany } from 'src/api/services/employeeServices';
import DeleteModal from '../shared/Modal/DeleteModal';

const EmployeesTable = () => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<string>('');

  const { companyId } = useAppSelector((state: RootState) => state.user);
  const { data } = useGetDepartments(companyId);
  const { handleDeleteEmployee } = useDeleteEmployee();

  const { handleUpdateEmployee, register, errors, watch, setValue, reset } = useUpdateEmployee();
  const fetchCompanyEmployees = async (
    params: PaginationState & {
      filters?: FetchEmployeesParams;
      search?: string;
    },
  ) => {
    const { pageIndex, pageSize, filters } = params;
    const response = await getAllOfACompany({
      page: pageIndex + 1,
      pageSize,
      ...filters,
    });

    return {
      rows: response.employees,
      rowCount: response.total,
    };
  };

  const tabs = [
    {
      key: 'personal',
      label: 'Personal Information',
      content: (
        <div>
          <div className="mx-auto mt-10 space-y-5">
            {EMPLOYEE_PERSONAL_INFO_FORM?.map((field) => (
              <FormGenerator
                {...field}
                key={field.id}
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
              />
            ))}
          </div>
        </div>
      ),
    },
    {
      key: 'job',
      label: 'Job Details',
      content: (
        <div className="mx-auto mt-5 space-y-5">
          {EMPLOYEE_COMPANY_INFO_FORM(data)?.map((field) => (
            <FormGenerator
              {...field}
              key={field.id}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          ))}
        </div>
      ),
    },
  ];

  const columns = React.useMemo<ColumnDef<UpdateEmployee>[]>(
    () => [
      {
        accessorKey: 'name',
        header: ({ table }) => (
          <div className="flex items-center px-1">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            <span className="ml-3">Name</span>
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div className="flex items-center px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
              <Avatar className="mx-3">
                <AvatarImage src={row.original.imgUrl ?? ''} />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
              <p className="whitespace-nowrap text-sm">{`${row.original.firstName} ${row.original.lastName}`}</p>
            </div>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        header: () => <span>ID</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'email',
        header: () => <span>Email</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'dateJoined',
        header: 'Date joined',
        cell: (info) => moment(info.getValue<Date>()).format('MMMM Do YYYY'),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (info) => {
          const text = info.getValue() === 'ACTIVE' ? 'Active' : 'On Leave';

          return (
            <span
              className={`inline-flex whitespace-nowrap rounded-full px-3 py-0.5 text-xs font-semibold leading-5 ${
                info.getValue() === 'ACTIVE'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}
            >
              {text}
            </span>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        id: 'actions',
        cell: ({ row }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="flex h-8 w-8 items-center justify-center rounded-md border border-neutral-50">
                <EllipsisVertical className="h-4 w-4" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[147px]">
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  setOpen(true);
                  reset(row.original as any);
                }}
              >
                <EditIcon />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer">
                <EyeIcon />
                View Detail
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer text-error-700 focus:text-error-700"
                onClick={() => setSelectedRow(row.original._id ?? '')}
              >
                <Trash />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
        enableHiding: false,
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  const { imgUrl, role, firstName, lastName } = watch();

  const closeDeleteModal = () => setSelectedRow('');
  return (
    <>
      <TanstackTable
        columns={columns}
        queryKey="employees"
        fetchDataFunc={fetchCompanyEmployees}
        filters={EmployeeFilter}
      />
      <Drawer
        open={open}
        setOpen={setOpen}
        className="gap-0 overflow-y-auto"
        header={{
          title: 'Employee Details',
          description: 'Employee information',
        }}
      >
        <div className="flex items-center justify-between">
          <UserInfo
            className="mb-6 border-b border-t-0 pl-0"
            user={{
              identity: role || '',
              name: `${firstName} ${lastName}`,
              avatar: imgUrl || '',
            }}
          />
          <Button>Message</Button>
        </div>
        <form onSubmit={handleUpdateEmployee} className="">
          <Tabs tabs={tabs} />
          <div className="mt-4 flex justify-end">
            <Button type="submit">Save</Button>
          </div>
        </form>
      </Drawer>
      <DeleteModal
        description="Are you sure you want to delete this employee’s information? This action can not be undone."
        title="Delete Employee"
        open={!!selectedRow}
        setOpen={setSelectedRow as any}
        handleDelete={() => {
          handleDeleteEmployee(selectedRow);
          closeDeleteModal();
        }}
        handleCancel={closeDeleteModal}
      />
    </>
  );
};

export default EmployeesTable;
