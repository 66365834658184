import React, { FC } from 'react';
import { ReactComponent as EmptyStateIcon } from 'src/assets/empty-state.svg';
interface EmptyStateProps {
  text?: string;
}
const EmptyState: FC<EmptyStateProps> = ({ text = 'Empty data' }) => {
  return (
    <div className="flex h-[500px] flex-col items-center justify-center">
      <EmptyStateIcon />
      <p>{text}</p>
    </div>
  );
};

export default EmptyState;
