import AccountLayout from '../../components/onboarding/AccountLayout';
import { useSearchParams } from 'react-router-dom';
import { RESET_PASSWORD_FORM } from '../../constant/form';
import { FormGenerator } from '../../components/shared/FormFieldGenerator';
import { useResetPassword } from '../../api/hooks/authentication';
import { Loader } from '../../components/shared/Loader';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get('token');
  const { register, isPending, onResetPassword, errors } = useResetPassword({
    token: param || '',
  });

  return (
    <AccountLayout>
      <div className="max-w-[448px] px-4 sm:w-[448px] md:px-0">
        <h2 className="text-center text-2xl font-semibold text-neutral-900 sm:text-3xl">
          Reset Password
        </h2>
        <p className="pt-2 text-center text-base font-normal text-neutral-400">
          Enter your new and secure password.
        </p>
        <form onSubmit={onResetPassword} className="mx-auto mt-10 max-w-md space-y-5">
          {RESET_PASSWORD_FORM?.map((field) => (
            <FormGenerator {...field} key={field.id} register={register} errors={errors} />
          ))}
          <div>
            <button type="submit" className="button-class">
              <Loader loading={isPending}>Reset Password</Loader>
            </button>
          </div>
        </form>
      </div>
    </AccountLayout>
  );
};

export default ResetPassword;
