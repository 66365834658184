import AccountLayout from '../../components/onboarding/AccountLayout';
import { Link } from 'react-router-dom';
import { LOGIN_FORM } from '../../constant/form';
import { FormGenerator } from '../../components/shared/FormFieldGenerator';
import { useLogin } from '../../api/hooks/authentication';
import { Loader } from '../../components/shared/Loader';

const Login = () => {
  const { onLogin, isPending, register, errors } = useLogin();

  return (
    <AccountLayout>
      <div className="max-w-[448px] px-4 sm:w-[448px] md:px-0">
        <h2 className="text-2xl font-semibold text-neutral-900 sm:text-3xl">Login</h2>
        <p className="pt-2 text-base font-normal text-neutral-400">Enter your details</p>
        <form onSubmit={onLogin} className="mx-auto mt-10 max-w-md space-y-5">
          {LOGIN_FORM?.map((field) => (
            <FormGenerator {...field} key={field.id} register={register} errors={errors} />
          ))}
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <input type="checkbox" className="h-5 w-5 accent-primary-500 shadow-none" />{' '}
              <p className="text-sm font-medium">Remember me</p>
            </div>
            <Link className="text-sm font-medium text-primary-500" to={'/forgot-password'}>
              Forgot Password?
            </Link>
          </div>
          <div>
            <button
              // onClick={() => navigate('/dashboard')}
              type="submit"
              className="button-class"
            >
              <Loader loading={isPending}>Login</Loader>
            </button>
          </div>
        </form>
        <p className="mt-6 text-center text-base font-normal text-neutral-400">
          Don’t have an account?
          <Link className="text-base font-medium text-primary-500" to={'/signup'}>
            {' '}
            Signup
          </Link>
        </p>
      </div>
    </AccountLayout>
  );
};

export default Login;
