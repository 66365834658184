import AccountLayout from '../../components/onboarding/AccountLayout';
import { Link } from 'react-router-dom';
import { useForgotPassword } from '../../api/hooks/authentication';
import { FORGOT_PASSWORD_FORM } from '../../constant/form';
import { FormGenerator } from '../../components/shared/FormFieldGenerator';
import { Loader } from '../../components/shared/Loader';

const ForgetPassword = () => {
  const { onResetPasswordRequest, register, isPending, errors } = useForgotPassword();

  return (
    <AccountLayout>
      <div className="max-w-[448px] px-4 sm:w-[448px] md:px-0">
        <h2 className="text-center text-2xl font-semibold text-neutral-900 sm:text-3xl">
          Forget Password
        </h2>
        <p className="pt-2 text-center text-base font-normal text-neutral-400">
          Enter your email address, and we will send you a link to reset your password.
        </p>
        <form onSubmit={onResetPasswordRequest} className="mx-auto mt-10 max-w-md space-y-5">
          {FORGOT_PASSWORD_FORM.map((field) => (
            <FormGenerator {...field} key={field.id} register={register} errors={errors} />
          ))}
          <div>
            <button type="submit" className="button-class">
              <Loader loading={isPending}>Reset Password</Loader>
            </button>
          </div>
        </form>
        <p className="mt-6 text-center text-base font-normal text-neutral-400">
          Remember password?{' '}
          <Link className="text-base font-medium text-primary-500" to={'/login'}>
            {' '}
            Login
          </Link>
        </p>
      </div>
    </AccountLayout>
  );
};

export default ForgetPassword;
