export const getAccessToken = () => localStorage.getItem('work-intercept-access_token');
export const setAccessToken = (token: string) =>
  localStorage.setItem('work-intercept-access_token', token);
export const getRefreshToken = () => localStorage.getItem('work-intercept-refresh_token');
export const setRefreshToken = (token: string) =>
  localStorage.setItem('work-intercept-refresh_token', token);
export const clearTokens = () => {
  localStorage.removeItem('work-intercept-access_token');
  localStorage.removeItem('work-intercept-refresh_token');
};
