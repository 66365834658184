import { cn } from 'src/lib/utils';
import React, { FC } from 'react';
interface HeaderProps {
  title: string;
  className?: string;
}
const Header: FC<HeaderProps> = ({ title, className }) => {
  return <h2 className={cn('mb-8 text-2xl font-semibold', className)}>{title}</h2>;
};

export default Header;
